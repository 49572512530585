import React from "react"
import { ColorPalette } from "../Theme"

/** const */
const HeroshowcaseSection: React.FC = () => {
  return (
    <div>
      <img
        src="/showcase/norhart-showcase.png"
        alt="Norhart Apartments Showcase"
        loading="eager"
        className="img-fluid w-100"
      />
    </div>
  )
}

/** export */
export default HeroshowcaseSection
